'use client';

import React, { useState, useEffect } from 'react';
import { Button } from "@/components/ui/button";
import Link from 'next/link';
import { X } from 'lucide-react';
import GoogleAnalytics from './GoogleAnalytics';

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [cookiesAccepted, setCookiesAccepted] = useState(false);

  useEffect(() => {
    const cookieConsent = localStorage.getItem('cookieConsent');
    if (cookieConsent === 'true') {
      setCookiesAccepted(true);
    } else if (!cookieConsent) {
      setTimeout(() => setIsVisible(true), 1000); // Delay banner appearance
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setIsVisible(false);
    setCookiesAccepted(true);
  };

  const handleClose = () => {
    localStorage.setItem('cookieConsent', 'false');
    setIsVisible(false);
  };

  if (!isVisible && !cookiesAccepted) return null;

  return (
    <>
      {cookiesAccepted && <GoogleAnalytics />}
      {isVisible && (
        <div className="fixed bottom-0 left-0 right-0 bg-white p-4 shadow-md z-50 border-t border-gray-200">
          <div className="container mx-auto max-w-6xl flex flex-col md:flex-row items-center justify-between relative">
            <button 
              onClick={handleClose} 
              className="absolute top-0 right-0 p-2 text-gray-500 hover:text-gray-700 md:-top-2 md:-right-20"
              aria-label="Close"
            >
              <X size={16} />
            </button>
            <p className="text-sm mb-4 md:mb-0 md:mr-4 text-center md:text-left pr-8 md:pr-0">
              By continuing, you accept our use of cookies to enhance your experience.
              <Link href="/privacy-policy" className="text-primary hover:underline ml-1">
                Learn more
              </Link>
            </p>
            <div className="flex space-x-2 ">
              <Button onClick={handleAccept} variant="default" size="sm">
                Accept
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieBanner;

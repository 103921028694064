import(/* webpackMode: "eager" */ "/vercel/path0/web/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/node_modules/next/font/local/target.css?{\"path\":\"node_modules/geist/dist/sans.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/geist-sans/Geist-Variable.woff2\",\"variable\":\"--font-geist-sans\",\"weight\":\"100 900\"}],\"variableName\":\"GeistSans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/web/src/components/CookieBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/web/src/components/Navigation.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/src/styles/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["TRPCReactProvider"] */ "/vercel/path0/web/src/trpc/react.tsx");
